import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy, toODataString } from '@progress/kendo-data-query';
import { FloatingLabel } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { ListOfPaged, MinimumPage } from "../../classes/consts";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";


import ContractsService from "../../services/contracts.service";
import ContractTypesService from "../../services/contractType.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import clientsService from '../../services/clients.service';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');


export default function List(props) {

    const { t } = useTranslation();

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Data Hooks
    const [filterData, setFilterData] = React.useState({ created: null, appId: "", clientId: 0, clientName: "" });
    const [clientsData, setClientsData] = React.useState([]);

    const [contractTypes, setContractTypes] = React.useState([]);
    const [selectedContractType, setSelectedContractType] = React.useState({ contractTypeId: -1, name: "" });

    // Grid Hooks
    const [orderByString, setOrderByString] = React.useState("");
    const [sortState, setSortState] = React.useState([]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: MinimumPage() });
    const [total, setTotal] = React.useState(0);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    //Filtros Hook

    const [loadingClients, setLoadingClients] = React.useState(true);
    const [clientsFilter, setClientsFilter] = React.useState([]);
    const [clientsFilterNames, setClientsFilterNames] = React.useState([]);
    const [stateClientFilter, setStateClientFilter] = React.useState({ data: clientsFilterNames, value: "", });
    const [selectedClientFilter, setSelectedClientFilter] = React.useState("");


    const loadingPanel =
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>;

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }
        // default rendering for this cell
        return tdElement;
    }, []);


    const remove = React.useCallback((dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }, []);

    const removeDataItem = () => {
        ContractsService.deleteContract(dataItemToRemove.contractId).then(() => {
            setDataItemToRemove({});
            loadData();
            setVisibleRemove(false);
        }).catch((err) => {
            setVisibleError(true);
            setDataItemToRemove({});
            setVisibleRemove(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    const clickFilter = () => {

        let filter = "";

        if (selectedClientFilter !== "")
            filter += selectedClientFilter;

        if (selectedContractType.contractTypeId !== -1)
            if (filter === "")
                filter += "ContractTypeId eq " + selectedContractType.contractTypeId;
            else
                filter += " and ContractTypeId eq " + selectedContractType.contractTypeId;

        if (filterData.clientName !== "")
            if (filter === "")
                filter += 'Contains(ClientName,\'' + filterData.clientName + '\')';
            else
                filter += " and Contains(ClientName,'" + filterData.clientName + "')";

        if (filterData.created !== null)
            if (filter === "")
                filter += "CreationDate ge " + filterData.created.toJSON();
            else
                filter += " and CreationDate ge " + filterData.created.toJSON();

        console.log(filter);
        loadData(filter);

    }

    const loadData = React.useCallback((filter = "") => {

        setLoading(true);

        ContractsService.getContractsFiltered(filter, orderByString, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                json.contract.map((item) => {

                    item.creationDate = new Date(item.creationDate);
                    item.startDate = new Date(item.startDate);
                    item.endDate = new Date(item.endDate);
                    return item;
                })
                setClientsData(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }, [orderByString, pageState]);

    //Efectos

    React.useEffect(() => {

        setLoadingClients(true);
        clientsService.getClients()
            .then(response => response.data)
            .then(json => {
                json.splice(0, 0, { clientId: null, name: "", remarks: "" })
                setLoadingClients(false);
                json.splice(0, 0, { clientId: null, name: t("Licenses_ClientFilter_All_Text"), remarks: "" })
                setClientsFilter(json);
                setClientsFilterNames(json.map((e) => e.name));
                setLoadingClients(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }, [t]);

    React.useEffect(() => {
        setLoading(true);
        loadData();
    }, [pageState, loadData]);

    React.useEffect(() => {

        const getDataContractTypes = async () => {
            let listOfContractTypes = await ContractTypesService.getListOfContractTypes();
            listOfContractTypes.push({ contractTypeId: -1, name: t("Global_DropDownFilter_None_Selected") });
            setSelectedContractType({ contractTypeId: -1, name: t("Global_DropDownFilter_None_Selected") });
            setContractTypes(listOfContractTypes);
        }
        getDataContractTypes();

    }, [t]);


    React.useEffect(loadData, [sortState, pageState, orderByString, loadData]);

    const filterDataByClient = React.useCallback((value) => {
        const filter = {
            value: value,
            operator: "startswith",
            ignoreCase: true,
        };

        return value ? filterBy(clientsFilterNames, filter) : clientsFilterNames;
    }, [clientsFilterNames]);

    const FilterByClient = React.useCallback((client) => {

        console.log(client);

        let clientSelected = clientsFilter.find(obj => {
            return obj.name === client;
        })

        console.log(clientSelected);

        if (clientSelected != null && clientSelected?.name !== "") {
            setSelectedClientFilter("ClientName eq \'" + clientSelected.name + "\'");
            setStateClientFilter({
                data: filterDataByClient(clientSelected.name),
                value: clientSelected.name,
            });
        } else {
            setSelectedClientFilter("");
            setStateClientFilter({
                data: filterDataByClient(client),
                value: client,
            });
        }
    }, [clientsFilter, filterDataByClient]);

    const closeErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);
    const closeDeleteCallback = React.useCallback(() => { setVisibleRemove(false) }, []);
    const filterClient = React.useCallback(e => { setFilterData(prev => ({ ...prev, clientName: e.value })); }, []);

    const sortChange = React.useCallback((event) => {
        let oDataString = toODataString({ sort: event.sort });
        oDataString = oDataString.replace("name", "Name");
        oDataString = oDataString.replace("clientName", "ClientName");
        oDataString = oDataString.replace("contractTypeName", "ContractTypeName");
        oDataString = oDataString.replace("creationDate", "CreationDate");
        oDataString = oDataString.replace("startDate", "StartDate");
        oDataString = oDataString.replace("endDate", "EndDate");
        oDataString = oDataString.replace("$orderby", "?orderBy");
        setOrderByString(oDataString);
        setSortState(event.sort);
    }, []);


    const filterClient1 = React.useCallback(e => FilterByClient(e.target.value), [FilterByClient]);


    const pageChange = React.useCallback((event) => {
        setPageState({ ...pageState, skip: event.page.skip, take: event.page.take });
    }, [pageState]);

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/Contracts/' + cellProps.dataItem.contractId + '/Details'}
            isAdmin={props.isAdmin}
        />
    ), [props, remove]);

    const changedSelectedContractType = (e) => {
        setSelectedContractType(e.value);
    }

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeDeleteCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.clientName + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={closeDeleteCallback}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <div className="card">
                        <div className="card-body container-fluid">
                            <div className="row">
                                <div className="col-lg-2">
                                    <FloatingLabel
                                        style={{ width: "100%" }}
                                        label={t("Contract_ClientName_Label")}
                                        editorId={"clientNameFilterSelector"}
                                        editorValue={loading ? "" : "null"}
                                    >
                                        <AutoComplete
                                            id={"clientNameFilterSelector"}
                                            style={{ width: "100%" }}
                                            loading={loadingClients}
                                            data={stateClientFilter.data}
                                            value={stateClientFilter.value}
                                            onChange={filterClient1}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className="col-lg-4" >
                                    <DropDownList
                                        label={t("Contract_ContractType_Label")}
                                        name={'contractType'}
                                        loading={loading}
                                        data={contractTypes}
                                        value={selectedContractType}
                                        textField="name"
                                        dataItemKey="contractTypeId"
                                        onChange={changedSelectedContractType}
                                    />
                                </div>
                                <div className="col-lg-4" ></div>

                                <div className="col-lg-2 d-flex">

                                    <button
                                        onClick={clickFilter}
                                        className="k-button ms-auto mt-auto mb-0"
                                    >
                                        <span className="k-icon k-i-filter" /> &nbsp; {t("Global_Button_Text_Filter")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    {loading && loadingPanel}
                    <Grid
                        editField='inEdit'
                        cellRender={loadingCell}
                        pageable={{
                            pageSizes: ListOfPaged(),
                        }}
                        data={clientsData}
                        total={total}
                        skip={pageState.skip}
                        take={pageState.take}
                        scrollable={'none'}
                        sortable={{
                            allowUnsort: true,
                            mode: "single"
                        }}
                        sort={sortState}
                        onSortChange={sortChange}
                        onPageChange={pageChange}


                    >
                        {props.isAdmin ? <GridToolbar>
                            <div>
                                <Link to={'/Contracts/Create'}>
                                    <button title={t("Contract_Button_Tooltip_Create")} className="k-button k-primary">
                                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                    </button>
                                </Link>
                            </div>
                        </GridToolbar> : ""}
                        <Column cell={CommandCell} width={props.isAdmin ? "120px" : "80px"} sortable={false} filterable={false} />
                        <Column field="clientName" title={t("Contract_ClientName_Label")} />
                        <Column field="contractTypeName" title={t("Contract_ContractTypeName_Label")} />
                        <Column field="description" title={t("Contract_Description_Label")} />
                        <Column field="startDate" format="{0: dd/MM/yyyy}" title={t("Contract_StartDate_Label")} />
                        <Column field="endDate" format="{0: dd/MM/yyyy}" title={t("Contract_EndDate_Label")} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

